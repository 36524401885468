<template>
  <div id="contact-edit" v-cloak v-loading.fullscreen.lock="loading">
    <div class="container">
      <div style="display: flex; justify-content: space-between">
        <div class="float-top">
          <div class="info_header">
            {{ $t("ContactEdit.info") }}
            <div class="suspension">Basic Information</div>
          </div>
          <!-- <zc-icon-btn content="<tags:lang>submit</tags:lang>" btn_type="primary"
                              btn_class="search_button" icon="icon-tijiao"
                              v-if="rightShow('CrmContact@contactInfoPut')"
                              @click="submit"></zc-icon-btn> -->
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('CrmCus.customLib')"
              placement="top"
            >
              <i
                class="new-icon iconfont icon-liebiao"
                v-if="
                  (rightShow('CrmContact@getContactLabel') ||
                    rightShow('CrmContact@getContactField') ||
                    rightShow('CrmContact@getContactStar') ||
                    rightShow('CrmContact@getContactGroup') ||
                    rightShow('CrmContact@getContactRegion')) &&
                  is_delete == 0
                "
                @click="is_open_window_crm_cus = !is_open_window_crm_cus"
              ></i>
            </el-tooltip>
            <!--<zc-icon-btn content="<tags:lang>delete</tags:lang>" btn_type="danger"-->
            <!--btn_class="form_button" icon="icon-youxiang"-->
            <!--v-if="rightShow('CrmContact@deleteUser') && is_view == 2 && is_delete == 0"-->
            <!--@click="deleteUser"></zc-icon-btn>-->
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('ContactEdit.edit')"
              placement="top"
            >
              <i
                class="new-icon iconfont icon-bianji"
                v-if="
                  is_view == 1 &&
                  rightShow('CrmContact@contactInfoPut') &&
                  is_delete == 0
                "
                @click="view(id, 0)"
              ></i>
            </el-tooltip>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                <i class="new-icon iconfont icon-gongneng"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="1">
                  {{ $t("ContactEdit.record") }}
                </el-dropdown-item>
                <el-dropdown-item :command="2">
                  {{ $t("ContactEdit.sales") }}
                </el-dropdown-item>
                <el-dropdown-item :command="4">
                  {{ $t("ContactEdit.lock") }}
                </el-dropdown-item>
                <el-dropdown-item :command="5">
                  {{ $t("ContactEdit.support") }}
                </el-dropdown-item>
                <el-dropdown-item :command="6">
                  {{ $t("taskInfo.new") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('ContactEdit.log')"
              placement="top"
            >
              <i
                class="new-icon iconfont icon-caozuorizhi"
                @click="drawer = true"
              ></i>
            </el-tooltip>
          </div>
        </div>
        <div class="float-right">
          <div class="info_header">
            {{ $t("ContactEdit.follow") }}
            <div class="suspension">follow up</div>
          </div>
          <!-- <zc-icon-btn content="<tags:lang>submit</tags:lang>" btn_type="primary"
                              btn_class="search_button" icon="icon-tijiao"
                              v-if="rightShow('CrmContact@contactInfoPut')"
                              @click="submit"></zc-icon-btn> -->
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('ContactEdit.addFollow')"
              placement="top"
            >
              <i
                class="new-icon iconfont icon-tianjia"
                @click="createFollow"
                v-if="is_view == 1 && rightShow('CrmTask@taskInfo')"
              ></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div style="width: 100%; display: flex; justify-content: space-between">
        <div>
          <div class="company_info">
            <div class="main-left">
              <div class="header">
                <div class="word">
                  {{ accountForm.name.substr(0, 1) }}
                </div>
                <div class="company_name">
                  <p class="business-name">{{ accountForm.name }}</p>
                  <p class="email-name">{{ accountForm.email }}</p>
                </div>
                <div class="price-num">
                  <span>{{ $t("ContactEdit.priceCoefficient") }}</span>
                  <span>{{ accountForm.user_price_num }}</span>
                </div>
              </div>
              <el-tooltip
                class="item"
                effect="dark"
                :content="accountForm.profile"
                placement="top"
              >
                <p class="profile">
                  {{ accountForm.profile }}
                </p>
              </el-tooltip>
              <p class="user">
                <span style="padding-right: 20px">{{
                  $t("ContactEdit.text")
                }}</span>
                <span v-if="followGroup.length > 0">
                  <span
                    v-for="(item, index) in followGroup"
                    :key="index"
                    style="padding-right: 10px; border-right: 1px black dashed"
                  >
                    <el-image
                      v-if="item.photo"
                      style="
                        margin-left: 10px;
                        width: 27px;
                        height: 27px;
                        margin-right: 5px;
                        border-radius: 50%;
                      "
                      :src="item.photo"
                    ></el-image>
                    {{ item.name }}
                  </span>
                </span>
                <span v-else>
                  {{ $t("projectInfo.no") }}
                </span>
              </p>
              <p class="user">
                <span style="padding-right: 20px">
                  {{ $t("ContactEdit.tag") }}
                </span>
                <span id="only-change-tags" v-if="accountForm.tags.length > 0">
                  <el-tag
                    class="mr10"
                    v-for="item in accountForm.tags"
                    :key="item.name"
                    :color="item.value"
                    effect="dark"
                  >
                    {{ item.name }}
                  </el-tag>
                </span>
                <span v-else class="content">
                  {{ $t("projectInfo.no") }}
                </span>
              </p>
            </div>
          </div>
          <div>
            <div class="main-right">
              <div>
                <div class="source">
                  <span class="tit mr5">{{ $t("ContactEdit.source") }}</span>
                  <span class="yellow-text" v-if="accountForm.source == 0">{{
                    formateSource(accountForm.source)
                  }}</span>
                  <span v-else
                    ><el-tag @click="SourceClue()">{{
                      formateSource(accountForm.source)
                    }}</el-tag></span
                  >
                </div>
                <p>
                  <span class="title">
                    {{ $t("contactList.accessionName") }}
                  </span>
                  <span v-if="accountForm.account" class="content">
                    {{ accountForm.account }}
                  </span>
                  <span v-else class="content">
                    {{ $t("projectInfo.no") }}
                  </span>
                </p>
                <p>
                  <span class="title">
                    {{ $t("ContactEdit.region") }}
                  </span>
                  <span v-if="accountForm.user_group_id">
                    {{ accountForm.user_group_id }}
                  </span>
                  <span v-else class="content">
                    {{ $t("projectInfo.no") }}
                  </span>
                </p>
                <p v-for="(item, index) in accountForm.company" :key="index">
                  <span class="title">
                    {{ item.name }}
                  </span>
                  <span>
                    {{ item.value }}
                  </span>
                </p>
              </div>
              <div>
                <p>
                  <span class="title">
                    {{ $t("CrmCus.contactGroup") }}
                  </span>
                  <span v-if="accountForm.user_crm_group_id">
                    {{ accountForm.user_crm_group_id }}
                  </span>
                  <span v-else class="content">
                    {{ $t("projectInfo.no") }}
                  </span>
                </p>
                <p>
                  <span class="title">
                    {{ $t("ContactEdit.country") }}
                  </span>
                  <span
                    v-if="
                      Object.prototype.hasOwnProperty.call(
                        cacheAddr,
                        accountForm.country
                      )
                    "
                  >
                    {{ cacheAddr[accountForm.country]["name"] }}
                  </span>
                  <span v-else class="content">
                    {{ $t("projectInfo.no") }}
                  </span>
                </p>
                <p>
                  <span class="title">
                    {{ $t("ContactEdit.address") }}
                  </span>
                  <span
                    v-if="
                      Object.prototype.hasOwnProperty.call(
                        cacheAddr,
                        accountForm.country
                      )
                    "
                    class="address"
                  >
                    <span
                      v-if="
                        accountForm.addr.length >= 1 &&
                        cacheAddr[accountForm.country][
                          'children'
                        ].hasOwnProperty(accountForm.addr[0])
                      "
                    >
                      {{
                        cacheAddr[accountForm.country]["children"][
                          accountForm.addr[0]
                        ]["name"]
                      }}
                      <span
                        v-if="
                          accountForm.addr.length >= 2 &&
                          cacheAddr[accountForm.country]['children'][
                            accountForm.addr[0]
                          ]['children'].hasOwnProperty(accountForm.addr[1])
                        "
                      >
                        {{
                          cacheAddr[accountForm.country]["children"][
                            accountForm.addr[0]
                          ]["children"][accountForm.addr[1]]["name"]
                        }}
                        <span
                          v-if="
                            accountForm.addr.length >= 3 &&
                            cacheAddr[accountForm.country]['children'][
                              accountForm.addr[0]
                            ]['children'][accountForm.addr[1]][
                              'children'
                            ].hasOwnProperty(accountForm.addr[2])
                          "
                        >
                          {{
                            cacheAddr[accountForm.country]["children"][
                              accountForm.addr[0]
                            ]["children"][accountForm.addr[1]]["children"][
                              accountForm.addr[2]
                            ]["name"]
                          }}
                        </span>
                      </span>
                    </span>
                    {{ accountForm.address }}
                  </span>
                  <span v-else class="content">
                    {{ $t("projectInfo.no") }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="main-content">
            <div class="main-1">
              <div>
                <div class="title">
                  {{ $t("contactList.star") }}
                  <div class="suspension">Customer star rating</div>
                </div>
                <div class="content">
                  <div
                    v-for="(item, index) in accountForm.star"
                    class="form-item flex-a-center start-box"
                    :key="index"
                  >
                    <span class="company-tit"> {{ item.name }}：</span>
                    <el-rate disabled v-model="item['value']" allow-half>
                    </el-rate>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div class="main-2">
              <div>
                <div class="title">
                  {{ $t("contactList.contact") }}
                  <div class="suspension">Contact person</div>
                </div>
                <div class="content">
                  <div
                    v-for="(addItem, index) in accountForm.addArr"
                    :key="index"
                    class="user"
                  >
                    <div class="add-top">
                      <span class="user_name">{{ addItem.consignee }}</span>
                      <img
                        v-if="index === 0"
                        style="padding-left: 250px"
                        src="/public/images/champion.png"
                      />
                    </div>
                    <p>
                      <span class="title">
                        {{ $t("ContactEdit.appellation") }}
                      </span>
                      <span v-if="addItem.call_name == 0">{{
                        $t("ContactEdit.sir")
                      }}</span>
                      <span v-if="addItem.call_name == 1">{{
                        $t("ContactEdit.maam")
                      }}</span>
                    </p>
                    <div>
                      <span v-if="addItem.mobile" class="contact-span">
                        <span class="title">
                          {{ $t("ContactEdit.telephone") }}
                        </span>
                        <span class="phone-w ellipsis" :title="addItem.mobile">
                          <template v-if="addItem.areaCode">
                            +{{ addItem.areaCode }}
                          </template>
                          {{ addItem.mobile }}
                        </span>
                      </span>
                      <span v-if="addItem.email" class="contact-span">
                        <span class="title">
                          {{ $t("ContactEdit.email") }}
                        </span>
                        <span class="email-w ellipsis" :title="addItem.email">{{
                          addItem.email
                        }}</span>
                      </span>
                    </div>
                    <p class="address-block" style="line-height: 20px">
                      <span class="title">
                        {{ $t("ContactEdit.address") }}
                      </span>
                      <span>
                        <template
                          v-if="cacheAddr.hasOwnProperty(addItem.country)"
                        >
                          <template
                            v-if="
                              addItem.addr.length >= 1 &&
                              cacheAddr[addItem.country][
                                'children'
                              ].hasOwnProperty(addItem.addr[0])
                            "
                          >
                            {{
                              cacheAddr[addItem.country]["children"][
                                addItem.addr[0]
                              ]["name"]
                            }}
                            <template
                              v-if="
                                addItem.addr.length >= 2 &&
                                cacheAddr[addItem.country]['children'][
                                  addItem.addr[0]
                                ]['children'].hasOwnProperty(addItem.addr[1])
                              "
                            >
                              {{
                                cacheAddr[addItem.country]["children"][
                                  addItem.addr[0]
                                ]["children"][addItem.addr[1]]["name"]
                              }}
                              <template
                                v-if="
                                  addItem.addr.length >= 3 &&
                                  cacheAddr[addItem.country]['children'][
                                    addItem.addr[0]
                                  ]['children'][addItem.addr[1]][
                                    'children'
                                  ].hasOwnProperty(addItem.addr[2])
                                "
                              >
                                {{
                                  cacheAddr[addItem.country]["children"][
                                    addItem.addr[0]
                                  ]["children"][addItem.addr[1]]["children"][
                                    addItem.addr[2]
                                  ]["name"]
                                }}
                              </template>
                            </template>
                          </template>
                        </template>
                        {{ addItem.address }}
                      </span>
                    </p>
                    <p class="desc-block" v-if="addItem.desc">
                      {{ addItem.desc }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="follow-up">
          <div v-if="orderSnCol[this.orderSnIndex].data.length">
            <div
              class="info-item"
              v-for="(tableItem, index) in orderSnCol[orderSnIndex].data"
              :key="index"
            >
              <div v-if="orderSnIndex == 0">
                <div class="info-top flex-between flex">
                  <div class="info-top_left">
                    <span>
                      {{ tableItem.admin_name }}
                    </span>
                  </div>
                  <span class="time">
                    {{ tableItem.create_time }}
                  </span>
                  <div v-if="is_view == 1 && rightShow('CrmTask@taskInfo')">
                    <i
                      class="new-icon el-icon-close"
                      @click="deleteProgress(tableItem.id)"
                    ></i>
                  </div>
                </div>
                <div class="info-span" style="display: flex">
                  <div style="width: 180px">
                    {{ $t("ContactEdit.followType") }}
                    ：<span>{{ getIcon(tableItem.type) }}</span>
                  </div>

                  <div>
                    {{ $t("contactList.contact") }}
                    ：
                    <span class="black-text">{{ tableItem.user_name }}</span>
                  </div>
                </div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="tableItem.content"
                  placement="top"
                >
                  <p class="detail-info">
                    {{ tableItem.content }}
                  </p>
                </el-tooltip>
              </div>
              <div v-else>
                <div class="info-top flex-between">
                  <div class="info-top_left">
                    <span
                      >{{ tableItem.admin_name
                      }}{{ formateOrderName(orderSnIndex) }}:</span
                    >
                    <span></span>
                  </div>
                  <span class="time" v-if="orderSnIndex == 5">
                    {{ tableItem.create_time }}
                  </span>
                  <span class="time" v-else>
                    {{ tableItem.add_time }}
                  </span>
                </div>
                <p class="detail-info">
                  {{ $t("ContactEdit.purchaseOrder") }}
                  :
                  <el-tag @click="jumpSource(tableItem.id)">{{
                    tableItem.order_sn
                  }}</el-tag>
                </p>
                <div class="info-span">
                  <span
                    >{{ $t("ContactEdit.status") }}：{{
                      formateOrder(tableItem.order_status, orderSnIndex)
                    }}</span
                  >
                  <i></i>
                </div>
                <div class="info-span">
                  <span>sku_id：{{ tableItem.sku_id }}</span>
                </div>
                <div class="info-span" v-if="tableItem.total_amount">
                  <span
                    >{{ $t("ContactEdit.totalPrice") }}：{{
                      tableItem.total_amount
                    }}</span
                  >
                </div>
                <div class="info-span" v-else-if="tableItem.goods_price">
                  <span
                    >{{ $t("ContactEdit.totalPrice") }}：{{
                      tableItem.goods_price
                    }}</span
                  >
                </div>
              </div>
            </div>
            <el-pagination
              @size-change="getOrderSn"
              @current-change="getOrderSn"
              :current-page.sync="orderSnCol[0].page"
              :page-size.sync="orderSnCol[0].pageSize"
              layout="prev, pager, next"
              :total.sync="orderSnCol[0].total"
            >
            </el-pagination>
          </div>
          <p v-else class="no-data">
            {{ $t("ContactEdit.noData") }}
          </p>
        </div>
      </div>
    </div>
    <el-drawer
      :title="$t('ContactEdit.log')"
      :visible.sync="drawer"
      size="535px"
      :before-close="handleClose"
    >
      <div
        v-if="id && source_id == 0 && logData.length > 0 && is_view == 1"
        class="log-container"
      >
        <div>
          <div
            class="log-item"
            v-for="(logItem, index) in logData"
            :key="index"
          >
            <span class="log-time">{{ logItem.log_time }}</span>
            <p class="log-info">
              {{ logItem.admin_name }} {{ logItem.action_desc
              }}{{ logItem.action_note }}
            </p>
          </div>
        </div>
      </div>
    </el-drawer>
    <zc-crm-cus-window
      :is_open="is_open_window_crm_cus"
      open_path=""
      page="2"
      call_back="getData"
    ></zc-crm-cus-window>
    <el-dialog
      :title="$t('ContactEdit.newFollow')"
      :visible.sync="createDialogVisible"
      width="450px"
      v-move
    >
      <div class="dialog-item mb20">
        <span class="dialog-name"
          ><span class="red">*</span>
          {{ $t("contactList.contact") }}
        </span>
        <el-select
          v-model="createDialogForm.consignee"
          size="small"
          filterable
          class="w300"
        >
          <el-option
            v-for="item in consigneePeople"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!--<div class="dialog-item">-->
      <!--<span class="dialog-name">-->
      <!--<span class="red">*</span>-->
      <!--<tags:lang>负责人</tags:lang>-->
      <!--</span>-->
      <!--<el-select v-model="createDialogForm.followPeople" size="small" filterable class="w160">-->
      <!--<el-option v-for="item in followPeople" :key="item.id" :label="item.name"-->
      <!--:value="item.id">-->
      <!--</el-option>-->
      <!--</el-select>-->
      <!--</div>-->
      <div class="dialog-item mb20">
        <span class="dialog-name">
          <span class="red">*</span>
          {{ $t("ContactEdit.followType") }}
        </span>
        <el-select
          v-model="createDialogForm.followMode"
          size="small"
          filterable
          class="w300"
        >
          <el-option
            v-for="item in followMode"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex-a-start">
        <span class="dialog-name">
          {{ $t("ContactEdit.followInfo") }}
        </span>
        <el-input
          v-model="createDialogForm.content"
          type="textarea"
          size="small"
          class="w300"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createDialogVisible = false" size="small">
          {{ $t("cancel") }}
        </el-button>
        <el-button type="primary" @click="createSubmitAdd" size="small">
          {{ $t("confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ZcCrmCusWindow from "@/components/ZcCrmCusWindow.vue";
import { toMessage } from "@/utils/message";
import "@/plugins/move";
export default {
  components: {
    ZcCrmCusWindow,
  },
  name: "ContactEdit",
  data() {
    return {
      cacheAddr: {},
      id: "",
      logData: [],
      drawer: false,
      userIndex: 0,
      contactTitle: this.$t("ContactEdit.addContact"),
      source_id: "0",
      is_view: "0",
      is_delete: 0,
      is_edit: "" == 0,
      iskeyShow: 0,
      iskeyShowProgess: 0,
      iskeyShowLabel: 0,
      is_open_window_crm_cus: false,
      loading: true,
      accountForm: {
        name: "",
        email: "",
        profile: "",
        account: "",
        password: "",
        msPassword: "",
        addArr: [],
        user_price_num: "",
        country: "",
        addr: [],
        address: "",
        user_crm_group_id: "",
        tags: [],
        user_group_id: "",
        follow: [],
        company: [],
        star: [],
        is_login: false,
        deleteLogin: true,
        isPassword: true,
      },
      sexOptions: [
        {
          value: "0",
          name: this.$t("ContactEdit.sir"),
        },
        {
          value: "1",
          name: this.$t("ContactEdit.maam"),
        },
      ],
      isEdit: "",
      groupOption: [],
      countryOption: [],
      dialogVisible: false,
      createDialogVisible: false,
      dialogForm: {
        consignee: "",
        call_name: "",
        areaCode: "",
        mobile: "",
        zipcode: "",
        email: "",
        country: "",
        addr: [],
        address: "",
        desc: "",
        order_index: 1,
      },
      createDialogForm: {
        consignee: "",
        content: "",
        followMode: "",
      },
      currentIndex: "",
      allAdd: [],
      desc: [""],
      // showPasswordTips: false,
      AllAddr: [],
      AllCallAddr: [],
      group: [],
      userGroup: [],
      tagsGroup: [
        {
          value: 1,
          label: this.$t("projectInfo.blueTag"),
          children: [],
        },
        {
          value: 2,
          label: this.$t("projectInfo.redTag"),
          children: [],
        },
        {
          value: 3,
          label: this.$t("projectInfo.yellowTag"),
          children: [],
        },
        {
          value: 4,
          label: this.$t("projectInfo.orangeTag"),
          children: [],
        },
        {
          value: 5,
          label: this.$t("projectInfo.greenTag"),
          children: [],
        },
      ],
      allTags: {},
      hasTags: {},
      hasStar: {},
      hasCompany: {},
      followGroup: [],
      company: [],
      starGroup: [],
      starArray: [],
      companyArray: [],
      consigneePeople: [],
      orderSnIndex: 0,
      orderSnCol: [
        //获取0线索  1备案单  2销售单  3报价单   4锁库单  5售后单
        {
          label: this.$t("ContactEdit.contact"),
          total: "",
          page: 1,
          pageSize: 4,
          data: [],
        },
      ],
      followMode: [
        {
          id: 0,
          name: this.$t("ContactEdit.telephoneContact"),
          icon: "icon-dianhua",
        },
        {
          id: 1,
          name: this.$t("ContactEdit.emailContact"),
          icon: "icon-xinxiang",
        },
        {
          id: 2,
          name: this.$t("ContactEdit.messageContact"),
          icon: "icon-duanxin",
        },
        {
          id: 3,
          name: this.$t("ContactEdit.visit"),
          icon: "icon-zoufang",
        },
        {
          id: 4,
          name: this.$t("ContactEdit.meeting"),
          icon: "icon-huiyi",
        },
        {
          id: 5,
          name: this.$t("ContactEdit.other"),
          icon: "icon-qita",
        },
      ],
      activeIndex: 0,
      showMore: false,
      is_isset_pass: false,
    };
  },
  computed: {
    showPasswordTips() {
      return this.accountForm.password.length == 0 &&
        this.accountForm.msPassword.length > 0
        ? true
        : false;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  //更改标签颜色
  updated: function () {
    let that = this;
    let a = that.$refs["myCascader"];
    if (a != null) {
      let c = that.$refs["myCascader"].$el;
      var cl = c.getElementsByClassName("el-cascader__tags");
      if (cl.length > 0) {
        var d = cl[0].getElementsByClassName("el-tag--info");
        for (let i = 0; i < d.length; i++) {
          let m =
            that.returnColor(that.allTags[d[i].textContent]["value"]) +
            " el-tag--info el-tag--small el-tag--light";
          d[i].className = m;
        }
      }
    }
  },
  inject: ["rightShow"],
  methods: {
    formateOrder: function (value, index) {
      //获取0线索  1备案单  2销售单  3报价单   4锁库单  5售后单
      let orderStatus = {};
      if (index == 0) {
        orderStatus = {
          0: this.$t("ContactEdit.telephoneContact"),
          1: this.$t("ContactEdit.emailContact"),
          2: this.$t("ContactEdit.messageContact"),
          3: this.$t("ContactEdit.visit"),
          4: this.$t("ContactEdit.meeting"),
        };
      } else if (index == 1) {
        orderStatus = {
          0: this.$t("ContactEdit.needCheck"),
          1: this.$t("ContactEdit.recording"),
          2: this.$t("contactedit.completed"),
          4: this.$t("ContactEdit.needUpdate"),
          3: this.$t("contactedit.closed"),
        };
      } else if (index == 2) {
        orderStatus = {
          0: this.$t("contactedit.submitted"),
          1: this.$t("ContactEdit.checked"),
          2: this.$t("ContactEdit.needSend"),
          3: this.$t("ContactEdit.requesting"),
          4: this.$t("ContactEdit.outbounding"),
          5: this.$t("ContactEdit.sent"),
          6: this.$t("contactedit.completed"),
          9: this.$t("ContactEdit.splited"),
          10: this.$t("ContactEdit.combined"),
          7: this.$t("contactedit.cancelled"),
          8: this.$t("contactedit.draft"),
        };
      } else if (index == 3) {
        orderStatus = {
          0: this.$t("contactedit.submitted"),
          1: this.$t("contactedit.completed"),
          2: this.$t("contactedit.closed"),
        };
      } else if (index == 4) {
        orderStatus = {
          0: this.$t("contactedit.draft"),
          1: this.$t("ContactEdit.locking"),
          2: this.$t("ContactEdit.conversion"),
          3: this.$t("ContactEdit.complete"),
          4: this.$t("contactedit.cancelled"),
        };
      } else {
        orderStatus = {
          0: this.$t("contactedit.submitted"),
          1: this.$t("ContactEdit.processing"),
          2: this.$t("contactedit.completed"),
          3: this.$t("contactedit.cancelled"),
        };
      }
      return orderStatus[value];
    },
    formateSource: function (val) {
      let sourceObj = {
        0: this.$t("ContactEdit.text1"),
        1: this.$t("ContactEdit.text2"),
      };
      return sourceObj[val];
    },
    formateOrderName: function (val) {
      let sourceObj = {
        1: this.$t("ContactEdit.text3"),
        2: this.$t("ContactEdit.text4"),
        3: this.$t("ContactEdit.text5"),
        4: this.$t("ContactEdit.text6"),
        5: this.$t("ContactEdit.text7"),
      };
      return sourceObj[val];
    },
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    handleClose(done) {
      done();
    },
    handleCommand(command) {
      switch (command) {
        case 1:
          // replaceTab(
          //   "Sale",
          //   "recordList",
          //   "<tags:lang>备案单</tags:lang>-<tags:lang>列表</tags:lang>",
          //   {
          //     isTab: true,
          //     contact_user: this.accountForm.name,
          //   }
          // );
          break;
        case 2:
          // replaceTab(
          //   "Sale",
          //   "manualList",
          //   "<tags:lang>销售单</tags:lang>-<tags:lang>列表</tags:lang>",
          //   {
          //     isTab: true,
          //     contact_user: this.accountForm.name,
          //   }
          // );
          break;
        case 4:
          // replaceTab(
          //   "warehouse",
          //   "orderLock",
          //   "<tags:lang>锁库单</tags:lang>-<tags:lang>列表</tags:lang>",
          //   {
          //     isTab: true,
          //     contact_user: this.accountForm.name,
          //   }
          // );
          break;
        case 5:
          // replaceTab(
          //   "After",
          //   "saleAfterList",
          //   "<tags:lang>售后单</tags:lang>-<tags:lang>列表</tags:lang>",
          //   {
          //     isTab: true,
          //     contact_user: this.accountForm.name,
          //   }
          // );
          break;
        case 6:
          this.createTask();
          break;
      }
    },
    getIcon(type) {
      let icon = "";
      this.followMode.forEach((item) => {
        if (item.id == type) {
          icon = item.name;
        }
      });
      return icon;
    },
    //是否增加登录数据
    addLogin() {
      this.accountForm.deleteLogin = true;
    },
    //清除登录数据
    clearLogin() {
      this.$confirm(this.$t("ContactEdit.confirmToDelete"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.accountForm.is_login = false;
          this.accountForm.account = "";
          this.accountForm.password = "";
          this.accountForm.msPassword = "";
          this.accountForm.deleteLogin = false;
        })
        .catch(() => {});
    },
    //登录名检测只能是英文 数字 *-_@+.
    numAccount() {
      if (this.accountForm.account) {
        let account = this.accountForm.account;
        account = account.replace(/([^\d^*^\-^_^@^+^A-Z^a-z])/g, "");
        this.accountForm.account = account;
        this.accountForm.deleteLogin = true;
      }
    },
    //区号和手机只能填写数字
    numMobile(type) {
      if (this.dialogForm[type]) {
        let account = this.dialogForm[type];
        account = account.replace(/([^\d])/g, "");
        this.dialogForm[type] = account;
      }
    },
    //校验邮箱
    checkEmail() {
      return true;
      // var reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/; //正则表达式
      // if (reg.test(v)) { //正则验证不通过，格式不对
      // 	//通过
      // 	return true
      // } else {
      // 	//失败
      // 	toMessage('error', '<tags:lang>Error in mailbox format</tags:lang>')
      // 	return false
      // }
    },
    //校验登录名
    checkLoginName(v) {
      var reg = /^[a-zA-Z0-9*\-_@+.]*$/; //正则表达式
      if (reg.test(v)) {
        //正则验证不通过，格式不对
        //通过
        return true;
      } else {
        //失败
        this.$message(toMessage("error", this.$t("ContactEdit.error")));
        return false;
      }
    },
    //校验联系人电话
    checkMobile(v) {
      if (!isNaN(v)) {
        //正则验证不通过，格式不对
        //通过
        if (v.length > 11) {
          //toMessage('error', '<tags:lang>请填写正确的电话格式</tags:lang>')
          return false;
        }
        return true;
      } else {
        //失败
        //toMessage('error', '<tags:lang>请填写正确的电话格式</tags:lang>')
        return false;
      }
    },
    handleExpandChange() {
      this.$nextTick(function () {
        let cc = this.$refs["myCascader"].popperJS;
        if (cc != null) {
          let c = cc._popper;
          let b = c.getElementsByClassName("el-cascader-panel");
          let d = b[0].childNodes;
          //增加按钮
          if (this.rightShow("CrmContact@getContactLabel")) {
            let left = d[0];
            if (left != null) {
              let e = left.getElementsByClassName("el-scrollbar__view");
              let child = e[0];
              let childLength = child.getElementsByClassName(
                "el-cascader-node"
              );
              if (childLength.length <= 5) {
                child.innerHTML +=
                  `<li role="menuitem" id="cascader-menu-7218-0-3" tabindex="-1" class="el-cascader-node" aria-haspopup="true" aria-owns="cascader-menu-7218-0"><label class="el-checkbox"><span class="el-checkbox__input button-icon-color"><span class="el-icon-plus"></span><input type="checkbox" aria-hidden="false" class="el-checkbox__original" value=""></span></label><span class="el-cascader-node__label"><div type="button" class="button-add-tags" onclick="vue.fn()">` +
                  this.$t("projectInfo.newTag") +
                  `</div></span></li>`;
              }
            }
          }
          //改变颜色
          let f = d[1];
          if (f != null) {
            f.style.minWidth = "220px";
            let e = f.getElementsByClassName("el-cascader-node");
            for (let i = 0; i < e.length; i++) {
              e[i].style.height = "40px";
              let objLeft = e[i].getElementsByTagName("label");
              objLeft[0].style.float = "right";
              objLeft[0].style.marginLeft = "70px";
              objLeft[0].style.bottom = "3px";
              e[i].append(objLeft[0]);

              let obj = e[i].getElementsByTagName("span");
              obj[0].style.backgroundColor = this.allTags[obj[0].innerHTML][
                "value"
              ];
              obj[0].style.color = "#fff";
              obj[0].style.maxWidth = "500px";
              obj[0].style.maxHeight = "28px";
              obj[0].style.textAlign = "center";
              obj[0].style.lineHeight = "28px";
              obj[0].title = e[i].innerHTML;
            }
          }
        }
      });
    },
    handleProgressPeople() {
      //this.iskeyShowProgess++;
    },
    handleChange() {
      //this.iskeyShowLabel++;
      let that = this;
      setTimeout(function () {
        let a = that.$refs["myCascader"];
        if (a != null) {
          let c = that.$refs["myCascader"].$el;
          var cl = c.getElementsByClassName("el-cascader__tags");
          if (cl.length > 0) {
            var d = cl[0].getElementsByClassName("el-tag--info");
            for (let i = 0; i < d.length; i++) {
              let m =
                that.returnColor(that.allTags[d[i].textContent]["value"]) +
                " el-tag--info el-tag--small el-tag--light";
              d[i].className = m;
            }
          }
        }
      }, 500);
    },
    SourceClue() {
      this.addTab(
        "CrmContact",
        "clueDetails",
        "<tags:lang>线索详情</tags:lang>",
        {
          user_id: this.id,
          is_view: 1,
        }
      );
    },
    createTask() {
      this.addTab(
        "CrmTask",
        "taskInfo",
        "<tags:lang>任务</tags:lang>-<tags:lang>add</tags:lang>",
        {
          source_id: this.id,
          source_type: 1,
          source_name: this.accountForm.name,
        }
      );
    },
    //删除跟进
    deleteProgress(progress_id) {
      this.$confirm(this.$t("management.confirmDelete"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.axios
          .post("/home/CrmContact/delFollow", {
            id: this.id,
            progress_id: progress_id,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.getOrderSn();
            this.getInfo(this.id);
          })
          .catch(() => {});
      });
    },
    //跳转源订单
    jumpSource(id) {
      console.log(id);
      if (this.orderSnIndex == 1) {
        //备案单跳转
        // this.addTab(
        //   "Sale",
        //   "quoteSheetInfo",
        //   `<tags:lang>备案单</tags:lang>-<tags:lang>详情</tags:lang>`,
        //   { id: id, is_record: 1, is_view: 1 }
        // );
      } else if (this.orderSnIndex == 2) {
        //销售单跳转
        // this.addTab(
        //   "Sale",
        //   "manualSales",
        //   `<tags:lang>销售单</tags:lang>-<tags:lang>详情</tags:lang>`,
        //   { id: id, is_confirm: 1 }
        // );
      } else if (this.orderSnIndex == 3) {
        //报价单跳转
        // this.addTab(
        //   "Sale",
        //   "quoteSheetInfo",
        //   `<tags:lang>price</tags:lang>-<tags:lang>详情</tags:lang>`,
        //   { id: id, is_record: 0, is_view: 1, is_jump: 1 }
        // );
      } else if (this.orderSnIndex == 4) {
        //锁库单跳转
        // this.addTab(
        //   "warehouse",
        //   "orderLockInfo",
        //   "<tags:lang>锁库单</tags:lang>-<tags:lang>详情</tags:lang>",
        //   { id: id, isView: 1 }
        // );
      } else if (this.orderSnIndex == 5) {
        //售后单跳转
        // this.addTab(
        //   "After",
        //   "saleAfterInfo",
        //   `<tags:lang>售后单</tags:lang>-<tags:lang>详情</tags:lang>`,
        //   { id: id, is_confirm: 1 }
        // );
      }
    },
    //0编辑
    view(id, is_confirm) {
      this.addTab(
        "CrmContact",
        "contactInfo",
        "<tags:lang>客户编辑</tags:lang>",
        {
          id: id,
          is_view: is_confirm,
        }
      );
    },
    //打开创建跟进弹窗
    createFollow() {
      //获取联系人
      console.log(123123);
      this.consigneePeople = [];
      this.accountForm.addArr.forEach((item) => {
        let obj = {};
        obj["name"] = item.consignee;
        obj["id"] = item.id;
        this.consigneePeople.push(obj);
      });
      //判断有没有联系人
      if (this.consigneePeople.length == 0) {
        this.$message(toMessage("error", "请添加联系人"));
        return;
      }
      this.createDialogForm = {
        consignee: "",
        followMode: "",
        content: "",
      };
      this.is_open_window_crm_cus = false;
      this.createDialogVisible = true;
    },
    //提交跟进信息
    createSubmitAdd() {
      //校验
      let ajax = JSON.parse(JSON.stringify(this.createDialogForm));
      ajax["id"] = this.id;
      this.$confirm(this.$t("ContactEdit.text8"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.axios
          .post("/home/CrmContact/subFollow", ajax)
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.createDialogVisible = false;
            this.getOrderSn();
            this.getInfo(this.id);
          })
          .catch(() => {});
      });
    },
    returnColor(color) {
      let select = {
        "#0A84FF": "only-el-color-tags-one",
        "#FF575C": "only-el-color-tags-two",
        "#F7BE16": "only-el-color-tags-three",
        "#FF7315": "only-el-color-tags-four",
        "#0ACE0D": "only-el-color-tags-five",
      };
      return select[color];
    },
    //改变orderSnindex
    changeOrderSnIndex(index) {
      this.activeIndex = index;
      this.orderSnIndex = index;
      if (this.orderSnCol[index].data.length == 0) {
        this.getOrderSn();
      }
    },
    //获取callback的内容
    getData(da) {
      if (this.is_view != 0) {
        return;
      }
      let data = JSON.parse(JSON.stringify(da));
      if (Object.prototype.hasOwnProperty.call(data, "tagsGroup")) {
        this.iskeyShowLabel++;
        this.handleChange();
        //this.addTags(data.tagsGroup)
        let tags = [];
        if (this.accountForm.tags) {
          this.accountForm.tags.forEach((item) => {
            let obj = {};
            obj["name"] = item[1];
            tags.push(obj);
          });
        }
        this.changeTagSort(data.tagsGroup, tags);
      }
      if (Object.prototype.hasOwnProperty.call(data, "starGroup")) {
        this.starGroup = data.starGroup;
        this.field_name_model(data.starGroup, 0);
      }
      if (Object.prototype.hasOwnProperty.call(data, "group")) {
        this.group = data.group;
      }
      if (Object.prototype.hasOwnProperty.call(data, "companyGroup")) {
        this.company = data.companyGroup;
        this.field_name_model(data.companyGroup, 1);
        this.changeCompanyField();
      }
      if (Object.prototype.hasOwnProperty.call(data, "regionGroup")) {
        this.iskeyShowProgess++;
        this.userGroup = data.regionGroup;
      }
    },
    //改变星级 去重使用
    changeStar() {
      this.starArray = [];
      this.accountForm.star.forEach((item) => {
        this.starArray.push(item.name);
      });
    },
    //改变自定义字段 去重使用
    changeCompanyField() {
      this.companyArray = [];
      this.accountForm.company.forEach((item) => {
        this.companyArray.push(item.name);
      });
    },
    //增加自定义字段
    addCompany(type) {
      this.accountForm[type].push({ name: "", value: "" });
    },
    //删除自定义地段
    delCompany(index, type) {
      this.accountForm[type].splice(index, 1);
      if (type == "star") {
        this.changeStar();
      }
      if (type == "company") {
        this.changeCompanyField();
      }
    },
    //添加详细地址
    putAddrDetailed() {
      if (this.accountForm.addr.length <= 0) {
        this.accountForm.address = "";
        this.$message(toMessage("error", this.$t("ContactEdit.error1")));
      }
    },
    //是否填写登录名密码
    password_type() {
      this.accountForm.password = "";
      this.accountForm.msPassword = "";
      if (!this.id) {
        this.accountForm.account = "";
      }
    },
    //获取0线索  1备案单  2销售单  3报价单   4锁库单  5售后单
    getOrderSn() {
      this.loading = true;
      this.axios
        .post("/home/CrmContact/getOrderSn", {
          user_id: Number(this.id),
          page: this.orderSnCol[this.orderSnIndex].page,
          pageSize: this.orderSnCol[this.orderSnIndex].pageSize,
          operation: this.orderSnIndex,
          is_delete: Number(this.is_delete),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          this.orderSnCol[this.orderSnIndex].data = res.data.data.list;
          this.orderSnCol[this.orderSnIndex].total = res.data.data.total;
          console.log(this.orderSnCol[0]);
        })
        .catch(() => {});
    },
    //获取信息
    getInfo(id) {
      this.loading = true;
      this.axios
        .post("/home/CrmContact/getUserInfo", {
          id: id,
          source_id: this.source_id,
          is_view: this.is_view,
          is_delete: this.is_delete,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            this.addTab(
              "CrmContact",
              "contactList",
              "<tags:lang>客户列表</tags:lang>"
            );
            return;
          }
          res.data.data.password = "";
          res.data.data.msPassword = "";
          this.accountForm.name = res.data.data.name;
          this.accountForm.profile = res.data.data.desc;
          this.accountForm.account = res.data.data.account;
          if (this.accountForm.account) {
            this.accountForm.isPassword = false;
          } else {
            this.accountForm.isPassword = true;
            this.accountForm.deleteLogin = false;
          }
          if (res.data.data.is_isset_pass) {
            this.is_isset_pass = true;
          }
          this.accountForm.addrArr = res.data.data.addrArr;
          this.accountForm.email = res.data.data.email;
          this.accountForm.source = res.data.data.source;
          if (res.data.data.country) {
            this.accountForm.country = res.data.data.country;
            this.changeCountry();
          }
          if (res.data.data.is_login) {
            this.accountForm.is_login = true;
          }
          if (res.data.data.province) {
            this.accountForm.addr = [];
            this.accountForm.addr.push(res.data.data.province);
            if (res.data.data.city) {
              this.accountForm.addr.push(res.data.data.city);
              if (res.data.data.district) {
                this.accountForm.addr.push(res.data.data.district);
              }
            }
          }
          if (res.data.data.company_addr) {
            this.accountForm.address = res.data.data.company_addr;
          }
          this.accountForm.user_group_id =
            res.data.data.user_group_id === 0
              ? ""
              : res.data.data.user_group_id;
          this.accountForm.user_price_num = res.data.data.user_price_num;
          this.accountForm.user_crm_group_id =
            res.data.data.user_crm_group_id === 0
              ? ""
              : res.data.data.user_crm_group_id;
          if (res.data.data.charge_user_ids) {
            this.accountForm.follow = res.data.data.charge_user_ids;
          }
          // this.accountForm.follow.forEach((item, index) => {
          // 	this.accountForm.follow[index] = item * 1
          // })
          if (res.data.data.addArr) {
            res.data.data.addArr.forEach((item) => {
              item.country = item.country === 0 ? "" : item.country;
              item.province = item.province === 0 ? "" : item.province;
              item.city = item.city === 0 ? "" : item.city;
              item.district = item.district === 0 ? "" : item.district;
            });
            this.accountForm.addArr = res.data.data.addArr;
            this.accountForm.addArr.forEach((item) => {
              if (item.mobile) {
                let obj = item.mobile.split("|*|");
                if (obj.length == 2) {
                  item.mobile = obj[1];
                  item.areaCode = obj[0];
                } else {
                  item.mobile = obj[0];
                }
              }
              item.addr = [];
              if (item.country) {
                if (item.province) {
                  item.addr.push(item["province"]);
                  if (item.city) {
                    item.addr.push(item["city"]);
                    if (item.district) {
                      item.addr.push(item["district"]);
                    }
                  }
                }
              }
            });
          }
          if (res.data.data.group) {
            this.group = res.data.data.group;
          }

          if (res.data.data.tagsGroup) {
            //this.addTags(res.data.tagsGroup)
          }
          if (res.data.data.tags) {
            if (this.is_view != 0) {
              this.accountForm.tags = res.data.data.tags;
            } else {
              //赋值标签
              //this.changeAccTags(res.data.tags)
              this.changeTagSort(res.data.data.tagsGroup, res.data.data.tags);
            }
          } else {
            if (res.data.data.tagsGroup) {
              this.changeTagSort(res.data.data.tagsGroup, res.data.data.tags);
            }
          }
          if (res.data.data.userGroup) {
            this.userGroup = res.data.data.userGroup;
          }
          if (res.data.data.followGroup) {
            this.followGroup = res.data.data.followGroup;
          }
          if (res.data.data.company) {
            this.company = res.data.data.company;
            if (this.is_view == 0) {
              this.field_name_model(res.data.data.company, 1);
            }
          }
          if (res.data.data.starGroup) {
            this.starGroup = res.data.data.starGroup;
            if (this.is_view == 0) {
              this.field_name_model(res.data.data.starGroup, 0);
            }
          }
          if (res.data.data.customField) {
            this.accountForm.company = res.data.data.customField;
          }
          if (res.data.data.customStar) {
            this.accountForm.star = [];
            res.data.data.customStar.forEach((item) => {
              item.value = item.value * 1;
              this.accountForm.star.push(item);
            });
          }
          if (Object.prototype.hasOwnProperty.call(res.data.data, "logData")) {
            this.logData = res.data.data.logData;
          }
          this.changeStar();
          this.changeCompanyField();
          this.handleChange();
        })
        .catch(() => {});
    },
    //储存自定义字段 星级  键值对
    field_name_model(data, index) {
      if (index == 0) {
        //星级
        this.hasStar = {};
        data.forEach((item) => {
          this.hasStar[item.id] = item;
        });
      }
      if (index == 1) {
        this.hasCompany = {};
        //自定义字段
        data.forEach((item) => {
          this.hasCompany[item.id] = item;
        });
      }
    },
    //改变标签的形式
    changeAccTags(data) {
      this.hasTags = [];
      this.accountForm.tags = [];
      data.forEach((item) => {
        let obj = [];
        if (item["value"] == "#0A84FF") {
          //蓝色
          obj.push(1);
        }
        if (item["value"] == "#FF575C") {
          //红色
          obj.push(2);
        }
        if (item["value"] == "#F7BE16") {
          //黄色
          obj.push(3);
        }
        if (item["value"] == "#FF7315") {
          //橙色
          obj.push(4);
        }
        if (item["value"] == "#0ACE0D") {
          //绿色
          obj.push(5);
        }
        obj.push(item.name);
        this.accountForm.tags.push(obj);
        this.hasTags[item["name"]] = item;
      });
    },
    //添加标签
    addTags(data) {
      //this.tagsGroup = data
      this.tagsGroup[0]["children"] = [];
      this.tagsGroup[1]["children"] = [];
      this.tagsGroup[2]["children"] = [];
      this.tagsGroup[3]["children"] = [];
      this.tagsGroup[4]["children"] = [];
      this.allTags = {};
      data.forEach((item) => {
        let obj = [];
        obj["label"] = item["name"];
        obj["value"] = item["id"];
        obj["color"] = item["value"];
        if (item["value"] == "#0A84FF") {
          //蓝色
          this.tagsGroup[0]["children"].push(obj);
        }
        if (item["value"] == "#FF575C") {
          //红色
          this.tagsGroup[1]["children"].push(obj);
        }
        if (item["value"] == "#F7BE16") {
          //黄色
          this.tagsGroup[2]["children"].push(obj);
        }
        if (item["value"] == "#FF7315") {
          //橙色
          this.tagsGroup[3]["children"].push(obj);
        }
        if (item["value"] == "#0ACE0D") {
          //绿色
          this.tagsGroup[4]["children"].push(obj);
        }
        this.allTags[item["name"]] = item;
      });
    },
    //重组标签的顺序 data 所有标签 tags选择的标签
    changeTagSort(data, tags) {
      this.tagsGroup[0]["children"] = [];
      this.tagsGroup[1]["children"] = [];
      this.tagsGroup[2]["children"] = [];
      this.tagsGroup[3]["children"] = [];
      this.tagsGroup[4]["children"] = [];
      this.allTags = {};
      data.forEach((item) => {
        let obj = [];
        obj["label"] = item["name"];
        obj["value"] = item["id"];
        obj["color"] = item["value"];
        if (item["value"] == "#0A84FF") {
          //蓝色
          this.tagsGroup[0]["children"].push(obj);
        }
        if (item["value"] == "#FF575C") {
          //红色
          this.tagsGroup[1]["children"].push(obj);
        }
        if (item["value"] == "#F7BE16") {
          //黄色
          this.tagsGroup[2]["children"].push(obj);
        }
        if (item["value"] == "#FF7315") {
          //橙色
          this.tagsGroup[3]["children"].push(obj);
        }
        if (item["value"] == "#0ACE0D") {
          //绿色
          this.tagsGroup[4]["children"].push(obj);
        }
        this.allTags[item["name"]] = item;
      });
      if (tags == undefined || tags == "" || tags.length == 0) {
        return;
      }
      let i = 0;
      let keyTags = {};
      this.tagsGroup.forEach((item, index) => {
        item["children"].forEach((it) => {
          let obj = [];
          obj.push(index + 1);
          obj.push(it["value"]);
          obj.push(i);
          obj.push(it);
          i++;
          keyTags[it["value"]] = obj;
        });
      });
      let sortTags = [];
      this.hasTags = [];
      this.accountForm.tags = [];
      tags.forEach((item) => {
        let obj = [];
        obj.push(keyTags[item["name"]][0]);
        obj.push(keyTags[item["name"]][1]);
        sortTags[keyTags[item["name"]][2]] = obj;
        this.hasTags[item["name"]] = item;
      });
      this.accountForm.tags = sortTags.filter(function (s) {
        return s;
      });
    },
    // 检测必填项
    checkRequire(requireVal, nowVal) {
      if (this.accountForm[requireVal] === "") {
        this.accountForm[nowVal] = "";
        this.showPasswordTips = true;
      } else {
        this.showPasswordTips = false;
      }
    },
    // 新增
    addAddress() {
      if (this.accountForm.addArr.length >= 5) {
        this.$message(toMessage("error", this.$t("ContactEdit.error2")));
        return;
      }
      this.dialogForm = {
        consignee: "",
        call_name: "",
        mobile: "",
        areaCode: "",
        zipcode: "",
        email: "",
        country: "",
        addr: [],
        address: "",
        desc: "",
        order_index: 1,
      };
      this.dialogVisible = true;
      this.currentIndex = Number(this.accountForm.addArr.length);
      if (this.accountForm.addArr.length == 0) {
        this.contactTitle = this.$t("ContactEdit.text9");
      } else {
        this.contactTitle = this.$t("ContactEdit.text10");
      }
    },
    // 编辑 地址
    editAddress(info, index) {
      if (index == 0) {
        this.contactTitle = this.$t("ContactEdit.text11");
      } else {
        this.contactTitle = this.$t("ContactEdit.text12");
      }
      this.dialogForm = JSON.parse(
        JSON.stringify(this.accountForm.addArr[index])
      );
      this.pushAddr();
      this.dialogVisible = true;
      this.currentIndex = index;
    },
    //删除地址
    delAddress(index) {
      this.$confirm(this.$t("ContactEdit.text13"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.accountForm.addArr.splice(index, 1);
        })
        .catch(() => {});
    },
    // 添加地址
    submitAdd() {
      if (this.dialogForm.consignee == "") {
        this.$message(toMessage("error", this.$t("ContactEdit.error3")));
        return;
      }
      if (
        this.dialogForm.mobile !== "" &&
        this.dialogForm.mobile !== undefined &&
        isNaN(this.dialogForm.mobile)
      ) {
        if (this.dialogForm.mobile.length < 7) {
          this.$message(toMessage("error", this.$t("ContactEdit.error4")));
          return;
        }
        this.$message(toMessage("error", this.$t("ContactEdit.error5")));
        return;
      }
      if (
        this.dialogForm.areaCode !== "" &&
        this.dialogForm.areaCode !== undefined &&
        isNaN(this.dialogForm.areaCode)
      ) {
        this.$message(toMessage("error", this.$t("ContactEdit.error6")));
        return;
      }
      //校验邮箱
      if (this.dialogForm.email != undefined && this.dialogForm.email != "") {
        let cEmail = this.checkEmail(this.dialogForm.email);
        if (!cEmail) {
          return;
        }
      }
      let temAdd = {
        consignee: this.dialogForm.consignee,
        call_name: this.dialogForm.call_name,
        mobile: this.dialogForm.mobile,
        areaCode: this.dialogForm.areaCode,
        country: this.dialogForm.country,
        addr: this.dialogForm.addr,
        address: this.dialogForm.address,
        zipcode: this.dialogForm.zipcode,
        email: this.dialogForm.email,
        desc: this.dialogForm.desc,
        order_index: this.dialogForm.order_index,
      };
      this.dialogVisible = false;
      if (this.accountForm.addArr.length === 0) {
        this.accountForm.addArr.push(temAdd);
      } else {
        if (this.accountForm.addArr.length === 1) {
          temAdd["order_index"] = 1;
        } else {
          if (temAdd["order_index"] == 0) {
            if (this.currentIndex <= this.accountForm.addArr.length) {
              //编辑
              this.accountForm.addArr.splice(this.currentIndex, 1);
            }
            // 把这个放到第一位
            this.accountForm.addArr.splice(0, 0, temAdd);
            // 把第一位的主要联系人置为否 0
            this.accountForm.addArr[1]["order_index"] = 1;
            return;
          }
        }
        this.accountForm.addArr.splice(this.currentIndex, 1, temAdd);
      }
    },
    //提交
    submit() {
      if (this.accountForm.name.trim() == "") {
        this.$message(toMessage("error", this.$t("ContactEdit.error7")));
        return;
      }
      if (
        Number(this.accountForm.user_price_num) == 0 ||
        this.accountForm.user_price_num == "" ||
        this.accountForm.user_price_num == undefined
      ) {
        this.$message(toMessage("error", this.$t("ContactEdit.error8")));
        return;
      }
      let bool = false;
      let text = "";
      let ajaxData = JSON.parse(JSON.stringify(this.accountForm));
      let keyCompany = {};
      let KeyStar = {};
      this.company.forEach((item) => {
        keyCompany[item.id] = item.name;
      });
      this.starGroup.forEach((item) => {
        KeyStar[item.id] = item.name;
      });
      ajaxData.star = this.accountForm.star.filter((item) => {
        if (item.name != "" && item.name != undefined) {
          if (item.value <= 0) {
            bool = true;
            if (Object.prototype.hasOwnProperty.call(KeyStar, item.name)) {
              text = KeyStar[item.name] + this.$t("ContactEdit.text14");
            } else {
              text = item.name + this.$t("ContactEdit.text14");
            }
          }
          return true;
        } else {
          return false;
        }
      });
      ajaxData.company = this.accountForm.company.filter((item) => {
        if (item.name != "" && item.name != undefined) {
          if (item.value == "" || item.value == undefined) {
            bool = true;
            if (Object.prototype.hasOwnProperty.call(keyCompany, item.name)) {
              text = keyCompany[item.name] + this.$t("ContactEdit.text15");
            } else {
              text = item.name + this.$t("ContactEdit.text15");
            }
          }
          return true;
        } else {
          return false;
        }
      });
      if (bool) {
        this.$message(toMessage("error", text));
        return;
      }
      if (ajaxData.star) {
        ajaxData.star.forEach((item) => {
          if (
            Object.prototype.hasOwnProperty.call(this.hasStar, item["name"])
          ) {
            item["field_name"] = this.hasStar[item["name"]]["name"];
          }
        });
      }
      if (ajaxData.company) {
        ajaxData.company.forEach((item) => {
          if (
            Object.prototype.hasOwnProperty.call(this.hasCompany, item["name"])
          ) {
            item["field_name"] = this.hasCompany[item["name"]]["name"];
          }
        });
      }

      if (ajaxData.tags) {
        let b = [];
        ajaxData.tags.forEach((item) => {
          if (item == undefined || item == "") {
            return;
          }
          let obj = {};
          if (Object.prototype.hasOwnProperty.call(this.hasTags, item[1])) {
            obj["id"] = this.hasTags[item[1]]["id"];
            obj["name"] = this.hasTags[item[1]]["name"];
            obj["field_name"] = this.hasTags[item[1]]["field_name"];
            b.push(obj);
            return;
          } else {
            obj["name"] = item[1];
            b.push(obj);
            return;
          }
        });
        ajaxData.tags = b;
      }
      //校验邮箱
      if (ajaxData.email != undefined && ajaxData.email != "") {
        let cEmail = this.checkEmail(ajaxData.email);
        if (!cEmail) {
          return;
        }
      }
      if (ajaxData.account) {
        let cLogin = this.checkLoginName(ajaxData.account);
        if (!cLogin) {
          return;
        }
      }
      if (ajaxData.addArr) {
        let bool = false;
        let text = "";
        ajaxData.addArr.forEach((item) => {
          if (
            !(
              item.mobile != undefined &&
              item.mobile != "" &&
              item.areaCode != undefined &&
              item.areaCode != ""
            )
          ) {
            if (isNaN(item.mobile) || isNaN(item.areaCode)) {
              text =
                this.$t("contactList.contact") +
                item.consignee +
                this.$t("ContactEdit.text16");
              bool = true;
            }
          }
        });
        if (bool) {
          this.$message(toMessage("error", text));
          return;
        }
      }
      //校验登录名
      if (this.id) {
        ajaxData["id"] = this.id;
        if (
          this.accountForm.is_login &&
          this.accountForm.account.trim() == ""
        ) {
          this.$message(toMessage("error", this.$t("ContactEdit.error9")));
          return;
        }
        //编辑
        if (
          (this.accountForm.password != "" ||
            this.accountForm.msPassword != "" ||
            this.accountForm.isPassword) &&
          this.accountForm.is_login
        ) {
          if (this.accountForm.account.trim() == "") {
            this.$message(toMessage("error", this.$t("ContactEdit.error9")));
            return;
          }
          console.log(456);
          if (
            this.accountForm.password !== "" &&
            this.accountForm.password.length < 6
          ) {
            this.$message(toMessage("error", this.$t("ContactEdit.error12")));
            return;
          }
          if (this.accountForm.password == "") {
            this.$message(toMessage("error", this.$t("ContactEdit.error10")));
            return;
          }
          if (this.accountForm.msPassword == "") {
            this.$message(toMessage("error", this.$t("ContactEdit.error11")));
            return;
          }
          if (
            this.accountForm.msPassword.length > 0 &&
            this.accountForm.password.length == 0
          ) {
            this.$message(toMessage("error", this.$t("ContactEdit.error10")));
            return;
          }
          if (this.accountForm.msPassword !== this.accountForm.password) {
            this.$message(toMessage("error", this.$t("ContactEdit.error13")));
            return;
          }
        }
      } else {
        //新增
        if (
          this.accountForm.account.trim() != "" ||
          this.accountForm.is_login
        ) {
          if (this.accountForm.account.trim() == "") {
            this.$message(toMessage("error", this.$t("ContactEdit.error9")));
            return;
          }
          if (
            this.accountForm.password !== "" &&
            this.accountForm.password.length < 6
          ) {
            this.$message(toMessage("error", this.$t("ContactEdit.error12")));
            return;
          }
          if (this.accountForm.password == "") {
            this.$message(toMessage("error", this.$t("ContactEdit.error10")));
            return;
          }
          if (this.accountForm.msPassword == "") {
            this.$message(toMessage("error", this.$t("ContactEdit.error11")));
            return;
          }
          if (
            this.accountForm.msPassword.length > 0 &&
            this.accountForm.password.length == 0
          ) {
            this.$message(toMessage("error", this.$t("ContactEdit.error10")));
            return;
          }
          if (this.accountForm.msPassword !== this.accountForm.password) {
            this.$message(toMessage("error", this.$t("ContactEdit.error13")));
            return;
          }
        }
      }
      if (this.source_id) {
        ajaxData["source_id"] = this.source_id;
      }
      this.loading = true;
      this.axios
        .post("/home/CrmContact/contactInfoPut", ajaxData)
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          if (res.status == 1001) {
            this.$message(toMessage("error", res.data + res.msg));
          } else {
            this.$message(toMessage());
          }
          this.addTab("CrmContact", "contactList", "");
        })
        .catch(() => {});
    },
    // 价格系数
    checkPrice() {
      if (!this.accountForm.user_price_num) {
        return;
      }
      var price = "";
      price = this.accountForm.user_price_num;
      price = price.replace(/[^\d.]/g, ""); //处理非数字
      let dotIndex = price.indexOf("."); //小数点的下标
      // 有小数点
      if (dotIndex >= 0) {
        // let firstNum = price.slice(0, dotIndex);
        if (Number(price) > 100) {
          price = price.slice(0, 3);
        } else {
          price = price.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, "$1"); //保留两位小数
        }
      } else {
        // 没有小数点
        if (Number(price) > 100) {
          price = price.slice(0, 2);
        }
      }
      this.accountForm.user_price_num = price;
    },
    // 失焦保留两位小数
    fillZero() {
      if (!this.accountForm.user_price_num) {
        return;
      }
      if (this.accountForm.user_price_num >= 100) {
        this.accountForm.user_price_num = 99.99;
      }
      var price = "";
      price = this.accountForm.user_price_num;
      if (!isNaN(price)) {
        price = Number(price).toString(); //忽略多余的0
        let dotIndex = price.indexOf("."); //小数点的下标
        if (dotIndex < 0) {
          dotIndex = price.length;
          price += ".";
        }
        while (price.length <= dotIndex + 2) {
          price += "0";
        }
      } else {
        price = "1.00";
      }
      this.accountForm.user_price_num = price;
    },
    //把cacheAddr转成数组类型
    pushAddr() {
      this.iskeyShow++;
      if (
        Object.prototype.hasOwnProperty.call(
          this.cacheAddr,
          this.dialogForm.country
        )
      ) {
        this.AllCallAddr = Object.values(
          JSON.parse(
            JSON.stringify(this.cacheAddr[this.dialogForm.country]["children"])
          )
        );
        // eslint-disable-next-line no-unused-vars
        this.AllCallAddr.forEach((item) => {
          item = this.reAddr(item);
        });
      } else {
        this.AllCallAddr = [];
      }
    },
    changeCountry() {
      this.iskeyShow++;
      this.accountForm.addr = [];
      this.accountForm.address = "";
      if (
        Object.prototype.hasOwnProperty.call(
          this.cacheAddr,
          this.accountForm.country
        )
      ) {
        this.AllAddr = Object.values(
          JSON.parse(
            JSON.stringify(this.cacheAddr[this.accountForm.country]["children"])
          )
        );
        // eslint-disable-next-line no-unused-vars
        this.AllAddr.forEach((item) => {
          item = this.reAddr(item);
        });
      } else {
        this.AllAddr = [];
      }
    },
    //递归改变AllAddr的children
    reAddr(addr) {
      if (Object.prototype.hasOwnProperty.call(addr, "children")) {
        addr["children"] = Object.values(addr["children"]);
        // eslint-disable-next-line no-unused-vars
        addr["children"].forEach((item) => {
          item = this.reAddr(item);
        });
      }
      return addr;
    },
    fn() {
      this.is_open_window_crm_cus = !this.is_open_window_crm_cus;
      this.iskeyShowLabel++;
      this.handleChange();
    },
  },
  created: function () {
    this.loading = false;
    let tabData = this.$route.params;
    if (tabData.is_view) {
      this.is_view = tabData.is_view;
    }
    if (tabData.is_delete) {
      this.is_delete = Number(tabData.is_delete);
    }
    if (tabData.id) {
      this.id = tabData.id;
      this.getInfo(tabData.id);
      this.getOrderSn();
      this.isEdit = true;
    } else {
      this.getInfo(0);
      this.isEdit = false;
    }
  },
  beforeCreate: function () {
    this.axios.get("/public/addr.js").then((res) => {
      this.cacheAddr = JSON.parse(res.data.slice(16, -1));
      this.countryOption = [];
      for (const key in this.cacheAddr) {
        this.countryOption.push({
          id: this.cacheAddr[key]["id"],
          name: this.cacheAddr[key]["name"],
        });
      }
    });
  },
};
</script>

<style scoped>
@import url("//at.alicdn.com/t/font_2070615_9fx3gapvx3.css");

#contact-edit {
  padding-left: 53px !important;
  padding-top: 28px !important;
  padding-right: 28px !important;
  padding-bottom: 28px !important;
  height: 100%;
  overflow: auto;
  background: #f9f9f9;
}
.container {
  width: 1230px;
  position: relative;
  margin: 0 auto;
}
.titH {
  color: #000000;
  font-size: 18px;
  margin-bottom: 19px;
  font-weight: 600;
}
.form-input {
  width: 378px;
}
.w540 {
  width: 540px;
}
.mt40 {
  margin-top: 40px;
}
.form-address-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.form-address-tit {
  width: 100px;
  color: #606266;
  margin-right: 10px;
  text-align: right;
  font-size: 14px;
  line-height: 28px;
  display: inline-block;
}

.el-select-dropdown__wrap {
  max-width: 300px;
}

.add-span {
  max-width: 300px;
  line-height: 34px;
  height: 34px;
  padding: 0;
  display: block;
}

.btn-box {
  display: none;
  justify-content: flex-end;
  position: absolute;
  right: 12px;
  top: 12px;
}
.btn-box i {
  font-size: 18px;
  cursor: pointer;
}
.mr16 {
  margin-right: 16px;
}
.add-more {
  width: 363px;
  height: 202px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid rgba(237, 237, 237, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-left: 4px solid #a6a6a6;
}

.add-more i {
  font-size: 40px;
  color: #666666;
}

.add-more span {
  margin-top: 10px;
  font-size: 14px;
  color: #666666;
}

.add-item {
  width: 363px;
  height: 202px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px 16px;
  border-left: 4px solid #a6a6a6;
  position: relative;
  font-size: 14px;
}

.add-item:nth-child(3n) {
  margin-right: 0;
}

.add-item:hover .btn-box {
  display: flex;
}
.phone-w {
  width: 135px;
  display: inline-block;
}

.email-w {
  display: inline-block;
  width: 130px;
  height: 17px;
}
.add-top {
  display: flex;
  align-items: center;
  color: #333333;
  margin-bottom: 11px;
}

.desc-block {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #ed722c;
  line-height: 26px;
}

.main-add {
  border-left: 4px solid #ff7315;
}
.contact-box {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.mr2 {
  margin-right: 2px;
}
.contact-span {
  display: flex;
  align-items: center;
  color: #333333;
  padding-top: 10px;
}
.contact-span i {
  color: #9b9b9b;
  font-size: 16px;
}
.detail-block {
  font-weight: 400;
  color: rgba(51, 51, 51, 0.8);
  line-height: 14px;
  margin-bottom: 15px;
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog-container {
  display: flex;
}

.dialog-left,
.dialog-right {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.dialog-name {
  width: 100px;
  text-align: right;
  margin-right: 10px;
}

.w160 {
  width: 160px;
}

.dialog-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.dialog-item.flex-a-start {
  align-items: flex-start;
}
.w550 {
  width: 540px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-dialog__header {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 41px;
  font-weight: bold;
  color: #333;
  font-size: 18px;
}

.red-tips {
  color: red;
  font-size: 12px;
  margin-left: 10px;
  line-height: 28px;
}
.m10 {
  margin-left: 10px;
}
.form-tit {
  line-height: normal;
  width: 70px;
}
.w378 {
  width: 378px;
}
.red {
  margin-right: 1px;
}
.op-box {
  margin-bottom: 16px;
}
.op-tit {
  margin-right: 38px;
  color: #666666;
  cursor: pointer;
}
.active-op {
  color: #ff7315;
  font-weight: 600;
  position: relative;
}
.active-op::after {
  content: " ";
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 2px;
  background: rgba(255, 115, 21, 1);
}
.only-change-tags {
}
.only-el-color-tags {
  background: red;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}
.only-el-color-tags-one {
  background: #0a84ff;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}
.only-el-color-tags-two {
  background: #ff575c;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}
.only-el-color-tags-three {
  background: #f7be16;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}
.only-el-color-tags-four {
  background: #ff7315;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}
.only-el-color-tags-five {
  background: #0ace0d;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}
.el-select-dropdown__item,
.el-select-dropdown__wrap {
  max-width: 378px;
}
.w103 {
  width: 103px;
}
.w345 {
  width: 345px;
}
.start-box i {
  font-size: 26px;
}
.start-box .num {
  font-size: 18px;
  margin-right: 5px;
}
.el-rate {
  width: 160px;
  height: auto;
}
.bottom-handel_tit {
  display: flex;
  color: #000000;
  margin-top: 34px;
  margin-bottom: 16px;
}
.bottom-handel_tit span {
  margin-right: 28px;
}
.bottom-handel_tit span:nth-child(1) {
  font-size: 16px;
  font-weight: 600;
}
.detail-info {
  color: #666666;
  line-height: 1.5;
  margin-bottom: 16px;
  width: 100%;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.info-span {
  margin-bottom: 8px;
  color: #000000;
}
.info-span span:first-child {
  color: #858585;
}
.info-item {
  padding: 16px;
  border-radius: 3px;
  border-top: 1px solid rgba(237, 237, 237, 1);
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 1);
  width: 100%;
}
.info-item .time {
  color: #333333;
  font-size: 14px;
}
.flex {
  display: flex;
  align-items: center;
}
.info-top {
  margin-bottom: 21px;
}
.info-top_left {
  color: #1c1c1e;
}
.submit-box {
  margin-top: 80px;
}
.submit-box >>> .el-button:first-child {
  margin-right: 76px;
}
.el-tag__close {
  background-color: transparent !important;
}
.business-info {
  width: 540px;
}
.business-name {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  margin-right: 12px;
}
.price-num {
  position: absolute;
  right: 30px;
  top: 30px;
  color: #666666;
  font-size: 16px;
}
.price-num span:nth-child(2) {
  color: #ff7315;
  margin-left: 5px;
  font-size: 24px;
  font-weight: 600;
}
.email {
  color: #ff7315;
  font-size: 14px;
  margin-bottom: 8px;
}

.text-align_left {
  text-align: left !important;
  width: auto;
}
.form-item {
  align-items: center;
}
.tit {
  font-size: 14px;
}
.source {
  font-size: 14px;
}
.business {
  margin-bottom: 4px;
}
.form-tit {
  color: #858585;
}
.company-tit {
  width: 160px;
  color: #858585;
  font-size: 14px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.draggable-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.el-tag--small {
  color: #fff;
}
.no-data {
  line-height: 40px;
  color: #666666;
  padding-left: 100px;
}
.logo-info {
  color: #1c1c1e;
  margin-bottom: 16px;
}
.logo-time {
  opacity: 0.6;
  margin-right: 16px;
}
.logTit {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #000000;
}
/* 标签面板 */
.tags-cascader >>> .el-cascader-menu:nth-child(2) .el-cascader-menu__list {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
}
.float-top {
  width: 800px;
  display: flex;
  justify-content: space-between;
}
.float-right {
  width: 400px;
  display: flex;
  justify-content: space-between;
}
.w55 {
  width: 55px;
}
.w300 {
  width: 300px;
}
.w225 {
  width: 225px;
}
.button-icon-color {
  color: #0a84ff;
}
.add-box {
  align-items: baseline;
}
.ml20 {
  margin-left: 20px;
}
.tit-top {
  margin-bottom: 16px;
}
.log-container {
  position: relative;
  width: 550px;
  margin-top: 78px;
}
.log-tit {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.log-item {
  margin-bottom: 16px;
  display: flex;
  color: #1c1c1e;
  line-height: 1.5;
  opacity: 0.6;
  margin-right: 12px;
}
.log-time {
  color: #888888;
  padding-right: 20px;
}
.log-info {
  max-width: 350px;
  color: #333333;
}
.log-h-container {
  height: 200px;
  overflow: hidden;
  position: relative;
}
.log-h-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 550px;
  height: 25px;
  background: linear-gradient(rgba(255, 255, 255, 0.001), white);
  pointer-events: none;
}
.more {
  color: #ff7315;
  font-size: 14px;
}
.disable-span {
  display: inline-block;
  text-decoration: line-through;
  color: #f56c6c;
}
.flex-a-start {
  align-items: flex-start !important;
}
.black-text {
  color: #000000 !important;
}
::-webkit-scrollbar {
  width: 3px;
}
.info_header {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
}
.company_info {
  width: 800px;
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  margin-top: 30px;
  padding: 20px;
  display: flex;
}
.main-left {
  width: 800px;
  position: relative;
  height: 200px;
}
.main-left .header {
  width: 800px;
  position: relative;
  display: flex;
  height: 80px;
}
.profile {
  width: 726px;
  height: 40px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #656565;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.word {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: #f9f9f9;
  border-radius: 50%;
  font-size: 30px;
  font-weight: bold;
  color: #ed712b;
}
.company_name {
  margin: 10px;
}
.company_name .business-name {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}
.company_name .email-name {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.main-left .user {
  width: 726px;
  height: 50px;
  overflow: auto;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #878787;
  line-height: 36px;
}
.main-right {
  width: 800px;
  margin-top: 20px;
  background: white;
  height: 150px;
  overflow-y: auto;
  display: flex;
  padding: 20px;
}
.main-right > div {
  flex: 1;
}
.main-right p span {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.main-right p .title {
  font-size: 14px;
  font-weight: 400;
  color: #878787;
  line-height: 36px;
}
.main-content {
  width: 800px;
  display: flex;
  justify-content: space-between;
}
.main-1,
.main-2 {
  width: 370px;
  height: 250px;
}
.main-1 > div > .title {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
  margin: 30px 0;
  position: relative;
}
.main-1 > div > .content {
  width: 100%;
  height: 210px;
  overflow-y: auto;
  background: white;
  padding: 0 20px;
}
.main-2 > div > .content > .user {
  padding: 20px 0;
  border-bottom: 1px solid #ebbb16;
}
.main-2 > div > .title {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
  margin: 30px 0;
  position: relative;
}
.main-2 > div > .content {
  width: 100%;
  height: 210px;
  overflow-y: auto;
  background: white;
  padding: 0 20px;
}
.follow-up {
  width: 400px;
  height: 720px;
  background: white;
  margin-top: 30px;
  position: relative;
}
.el-pagination {
  position: absolute;
  bottom: 10px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.new-icon {
  padding-left: 10px;
  font-size: 30px;
  color: #ed722c;
  cursor: pointer;
}
.user_name {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
}
.main-2 {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.main-2 .title {
  padding-right: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #888888;
}
.el-icon-close {
  font-size: 18px;
  font-weight: bold;
}
.el-drawer {
  overflow-y: auto;
}
.el-drawer :focus {
  outline: 0;
}
.el-drawer .el-drawer__body {
  margin-left: 50px;
}
.suspension {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  opacity: 0.1;
  position: absolute;
  top: 12px;
}
.el-drawer__header {
  font-size: 22px;
  font-weight: bold;
}
</style>